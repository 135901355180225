'use client'

import { HomepageBannerSliderType } from '@/api'
import { SliderComponent } from './slider-component'
import { gtmTracker } from '@/common/services/tracking/google/gtm-tracker'
import {
  DeviceBreakpoints,
  useBrowserMediaQuery,
} from '@/common/hooks/use-browser-media-query'

export type HomepageBannerProps = {
  isDeviceMobile: boolean
  homepageSliderData: HomepageBannerSliderType
}

export function HomepageBanner({
  isDeviceMobile,
  homepageSliderData,
}: HomepageBannerProps): JSX.Element {
  const isSm = useBrowserMediaQuery({ breakpoint: DeviceBreakpoints.Sm })

  return (
    <SliderComponent
      items={homepageSliderData?.items ?? []}
      isMobile={isDeviceMobile || isSm}
      config={homepageSliderData.config}
      onClick={(_slide, position) => gtmTracker.trackBannerClick(position)}
    />
  )
}
